define("discourse/plugins/discourse-unlock/discourse/initializers/extend-for-unlock", ["exports", "@ember/application", "@ember/runloop", "discourse/lib/ajax", "discourse/lib/load-script", "discourse/lib/plugin-api", "discourse/lib/preload-store", "discourse/raw-views/topic-status", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _application, _runloop, _ajax, _loadScript, _pluginApi, _preloadStore, _topicStatus, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UNLOCK_URL = "https://paywall.unlock-protocol.com/static/unlock.latest.min.js";
  function reset() {
    window._redirectUrl = null;
    window._lock = null;
    window._wallet = null;
    window._transaction = null;
  }
  var _default = _exports.default = {
    name: "apply-unlock",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.11.2", api => {
        _topicStatus.default.reopen(dt7948.p({
          statuses() {
            const results = this._super();
            if (this.topic.category?.lock) {
              results.push({
                openTag: "span",
                closeTag: "span",
                title: _discourseI18n.default.t("unlock.locked"),
                icon: this.topic.category.lock_icon
              });
            }
            return results;
          }
        }, [["method", "statuses", [(0, _decorators.default)()]]]));
        api.modifyClass("model:post-stream", {
          errorLoading(result) {
            const {
              status
            } = result.jqXHR;
            const {
              lock,
              url
            } = result.jqXHR.responseJSON;
            if (status === 402 && lock) {
              if ((0, _application.getOwner)(this).lookup("service:current-user")) {
                window._redirectUrl = url;
                return (0, _loadScript.default)(UNLOCK_URL).then(() => window.unlockProtocol.loadCheckoutModal());
              } else {
                return (0, _application.getOwner)(this).lookup("route:application").replaceWith("login");
              }
            } else {
              return this._super(result);
            }
          }
        });
        reset();
        const settings = _preloadStore.default.get("lock");
        if (settings && settings.lock_address) {
          window.addEventListener("unlockProtocol.status", _ref => {
            let {
              detail
            } = _ref;
            const {
              state
            } = detail;
            if (state === "unlocked" && window._redirectUrl && window._wallet) {
              const data = {
                lock: window._lock || settings.lock_address,
                wallet: window._wallet
              };
              if (window._transaction) {
                data["transaction"] = window._transaction;
              }
              const url = document.location.origin + window._redirectUrl;
              reset();
              return (0, _ajax.ajax)("/unlock.json", {
                type: "POST",
                data
              }).then(() => document.location.replace(url));
            }
          });
          window.addEventListener("unlockProtocol.authenticated", _ref2 => {
            let {
              detail
            } = _ref2;
            const {
              address
            } = detail;
            window._wallet = address;
          });
          window.addEventListener("unlockProtocol.transactionSent", _ref3 => {
            let {
              detail
            } = _ref3;
            const {
              hash,
              lock
            } = detail;
            window._transaction = hash;
            window._lock = lock;
          });
          window.unlockProtocolConfig = {
            network: settings.lock_network || 4,
            locks: {
              [settings.lock_address]: {}
            },
            icon: settings.lock_icon,
            callToAction: {
              default: settings.lock_call_to_action
            },
            referrer: "0x67dec02d34ea56bcf9f7c9b318298dda8c562080"
          };

          // preload unlock script
          (0, _runloop.next)(() => (0, _loadScript.default)(UNLOCK_URL));
        }
      });
    }
  };
});