define("discourse/plugins/discourse-unlock/discourse/controllers/admin-plugins-discourse-unlock", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/category", "discourse/models/group"], function (_exports, _controller, _object, _ajax, _ajaxError, _category, _group) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    lockedCategories: (0, _object.computed)("model.locked_category_ids", function () {
      const {
        locked_category_ids
      } = this.model;
      return locked_category_ids && locked_category_ids.length > 0 ? _category.default.findByIds(locked_category_ids) : [];
    }),
    changeLockedCategories(categories) {
      this.set("model.locked_category_ids", categories.mapBy("id"));
    },
    groupFinder(term) {
      return _group.default.findAll({
        term
      });
    },
    save() {
      this.setProperties({
        saving: true,
        saved: false
      });
      const {
        lock_address,
        lock_network,
        lock_icon,
        lock_call_to_action,
        locked_category_ids,
        locked_topic_icon,
        unlocked_group_name
      } = this.model;
      return (0, _ajax.ajax)("/admin/plugins/discourse-unlock.json", {
        type: "PUT",
        data: {
          lock_address,
          lock_network,
          lock_icon,
          lock_call_to_action,
          locked_category_ids,
          locked_topic_icon,
          unlocked_group_name
        }
      }).then(() => this.set("saved", true)).catch(_ajaxError.popupAjaxError).finally(() => this.set("saving", false));
    }
  }, [["method", "changeLockedCategories", [_object.action]], ["method", "save", [_object.action]]]));
});