define("discourse/plugins/discourse-unlock/templates/connectors/category-custom-security/lock-warning", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <p class="alert">{{i18n "unlock.category_security_warning"}}</p>
  */
  {
    "id": "wABTdl77",
    "block": "[[[10,2],[14,0,\"alert\"],[12],[1,[28,[35,0],[\"unlock.category_security_warning\"],null]],[13]],[],false,[\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-unlock/templates/connectors/category-custom-security/lock-warning.hbs",
    "isStrictMode": false
  });
});